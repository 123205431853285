const checkLists = document.querySelectorAll('.checklist');

export default function() {
  for (const checkList of checkLists) {
    const inputWrapList = checkList.querySelectorAll('.checklist__wrap');

    for (const inputWrap of inputWrapList) {
      const checkInput = inputWrap.querySelector('.checklist__input');
      const checkText = inputWrap.querySelector('.checklist__text');

      checkText.style.textDecoration = checkInput.checked ? 'line-through' : 'none'; //print styles
      checkText.style.marginBottom = '1em';                                          //print styles

      checkInput.addEventListener('change', function() {
        checkText.style.textDecoration = checkInput.checked ? 'line-through' : 'none';
      });
    }
  }
}
