const closeModalElements = document.querySelectorAll('[data-close-modal]');
const openModalElements = document.querySelectorAll('[data-open-modal]');

let onModalClose = null;

export default function() {
  for (const element of openModalElements) {
    element.addEventListener('click', function(e) {
      const id = element.getAttribute('data-open-modal');

      e.preventDefault();

      id && openModal(id);
    });
  }

  for (const element of closeModalElements) {
    element.addEventListener('click', closeModal);
  }
}

function openModal(id, {onOpen, onClose, slideTo}) {
  const modal = document.querySelector(`.modal[data-modal-id="${id}"]`);
  const oldActiveModal = document.querySelector('.modal--show');

  oldActiveModal && oldActiveModal.classList.remove('modal--show');

  if(modal) {
    modal.classList.add('modal--show');

    disableBodyScroll();
    resetSwiper(modal, slideTo);
    onOpen && onOpen();
    onModalClose = onClose;
  } else {
    console.log(`modal "${id}" not defined, check selector .modal[data-modal-id="${id}"]`);
  }
}

function closeModal() {
  const oldActiveModal = document.querySelector('.modal--show');

  oldActiveModal && oldActiveModal.classList.remove('modal--show');

  disableSwiper(oldActiveModal);
  enableBodyScroll();

  onModalClose && onModalClose();
}

function resetSwiper(modal, slideTo) {
  const swiperContainer = modal.querySelector('.swiper-container');
  const swiper = swiperContainer && swiperContainer.swiper;

  if(swiper) {
    swiper.enable();
    swiper.update();
    slideTo && swiper.slideTo(Number(slideTo), 0);
  }
}

function disableSwiper(modal) {
  const swiperContainer = modal.querySelector('.swiper-container');
  const swiper = swiperContainer && swiperContainer.swiper;

  if(swiper) {
    swiper.disable()
  }
}

function disableBodyScroll() {
  if(!document.body.classList.contains('body--fixed')) {
    document.body.style.top = `-${window.scrollY || window.pageYOffset || 0}px`;
    document.body.style.position = 'fixed';
    document.body.classList.add('body--fixed');
  }
}

function enableBodyScroll() {
  const scrollY = document.body.style.top;

  document.body.style.position = '';
  document.body.style.top = '';
  window.scrollTo(0, parseInt(scrollY || '0') * -1);
  document.body.classList.remove('body--fixed');
}

export { openModal };
