export default function(element, behavior='smooth'){
  if(typeof(element) == "string"){
    element = document.querySelector(element);
  }

  let x;
  if(element instanceof Element){
    x = offset(element).top;
  } else if (typeof element === 'number') {
    x = element;
  } else {
    return;
  }



  window.scrollTo({
    top: x,
    behavior
  });

  function offset(el) {
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }
}
