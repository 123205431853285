import Swiper from "swiper/swiper-bundle";

const subjectScrollerSwiperContainerList = document.querySelectorAll('.subjects-scroller__swiper-container');

export default function() {
  for (const subjectScrollerSwiperContainer of subjectScrollerSwiperContainerList) {
    const subjectSwiper = new Swiper(subjectScrollerSwiperContainer, {
      spaceBetween: 40,
      slidesPerView: 1,
      mousewheel: {
        forceToAxis: true
      },
      freeModeMomentumRatio: 0.5,
      freeModeMomentumVelocityRatio: 0.5,
      breakpoints: {
        639: {
          spaceBetween: 40,
          freeMode: true,
        },
        1023: {
          spaceBetween: 44,
          freeMode: true,
        }
      }
    });

    window.addEventListener('load', function() {
      subjectScrollerSwiperContainer.classList.add('subjects-scroller__swiper-container--loaded');
      subjectSwiper.update();
    });

    subjectSwiper.on('click', (swiper) => {
      setTimeout(() => subjectSwiper.slideTo(swiper.clickedIndex), 50);
    });
  }
}
