import Swiper from "swiper/swiper-bundle";
import docReady from "../utils/doc-ready";

export default function() {
  docReady(() => {
    const footerSwiperContainerList = document.querySelectorAll('.footer-swiper__container');

    for (const footerSwiperContainer of footerSwiperContainerList) {
      new Swiper(footerSwiperContainer, {
        spaceBetween: 40,
        mousewheel: {
          forceToAxis: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }
      });
    }
  });
}
