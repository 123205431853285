import Swiper from "swiper/swiper-bundle";
import docReady from "../utils/doc-ready";

export default function() {
  docReady(() => {
    const articleSwiperContainerList = document.querySelectorAll('.article-swiper__container');

    for (const articleSwiperContainer of articleSwiperContainerList) {
      new Swiper(articleSwiperContainer, {
        spaceBetween: 40,
        slidesPerView: 'auto',
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar',
        },
        mousewheel: {
          forceToAxis: true
        },
        breakpoints: {
          639: {
            spaceBetween: 40,
          },
          1023: {
            spaceBetween: 42,
          }
        }
      });
    }
  });
}
