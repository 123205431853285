import '@stylesheets/application.scss';

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import emoji from '@script/blocks/emoji';
import subjectsScroller from '@script/blocks/subjects-scroller';
import subjectsTags from '@script/blocks/subjects-tags';
import footerSwiper from '@script/blocks/footer-swiper';
import productSwiper from '@script/blocks/product-swiper';
import articleSwiper from '@script/blocks/article-swiper';
import printBlock from '@script/blocks/print-block';
import checklist from '@script/blocks/checklist';
import footnotes from '@script/blocks/footnotes';
import loadImage from '@script/blocks/load-image';
import form from '@script/blocks/form';
import banner from '@script/blocks/banner';
import header from '@script/blocks/header';
import headerSearch from '@script/blocks/header-search';
import fixedShare from '@script/blocks/fixed-share';
import modal from '@script/blocks/modal';
import modalGallery from '@script/blocks/modal-gallery';

import posts from '@script/blocks/posts';
import newsPage from '@script/pages/news';
import jumper from '@script/blocks/jumper';
import scrollEvents from '@script/utils/scroll-events';
import userSelect from '@script/blocks/user-select';

import docReady from '@script/utils/doc-ready';

loadImage();
docReady(() => {
  console.log('here');
  emoji();
  subjectsScroller();
  subjectsTags();
  footerSwiper();
  productSwiper();
  articleSwiper();
  printBlock();
  checklist();
  footnotes();
  form();
  banner();
  header();
  headerSearch();
  fixedShare();
  modal();
  modalGallery();
  jumper();

  posts('.posts');
  newsPage();
  scrollEvents();
  userSelect();
});
