const trigger = document.querySelector('.fixed-share-stop');
const fixedShare = document.querySelector('.fixed-share');
const progressEye = document.querySelector('.fixed-share__btn--views');
const articleNewsWrap = document.querySelector('.article__news-wrap');
const articleSection = document.querySelector('.article');
const limitsSection = articleNewsWrap || articleSection;

const floatWidget = document.querySelector('.float-widget');

const shareMenu = document.querySelector('.fixed-share__menu');
const openShareMenuBtn = document.querySelector('.fixed-share__btn--share');
const closeShareMenuBtns = document.querySelectorAll('.fixed-share__btn--close, .fixed-share__pane-link');

const copyBtn = document.querySelector('.fixed-share__copy-url');

let stop = 0;
let activeSectionParams = {};

export default function() {
  initShareLinks();

  if(fixedShare && limitsSection) {
    updateFixedShareLimits();

    window.addEventListener('resize', updateFixedShareLimits);
    window.addEventListener('load', updateFixedShareLimits);
    window.addEventListener('scroll', checkForFixedSharePosition, { passive: true });
  }

  openShareMenuBtn && openShareMenuBtn.addEventListener('click', function() {
    shareMenu.classList.add('fixed-share__menu--open');
  });

  for (const closeShareMenuBtn of closeShareMenuBtns) {
    closeShareMenuBtn.addEventListener('click', function() {
      shareMenu.classList.remove('fixed-share__menu--open');
    });
  }

  copyBtn && copyBtn.addEventListener('click', function(e) {
    e.preventDefault();

    let copyText = copyBtn.querySelector('input');

    if(copyText) {
      copyText.value = location.href;
      copyText.select();
      copyText.setSelectionRange(0, 99999);

      document.execCommand('copy');
      console.log("Copied the text: " + copyText.value);
    }
  });
}

function updateFixedShareLimits() {
  const fixedShareRect = fixedShare.getBoundingClientRect();
  const bodyHeight = document.body.clientHeight;
  const articleBottom = bodyHeight - limitsSection.getBoundingClientRect().height;

  if(trigger) {
    const triggerRect = trigger.getBoundingClientRect();

    stop = triggerRect.top + window.scrollY - window.innerHeight + fixedShareRect.height + fixedShareRect.width;
  } else {
    stop = fixedShareRect.height + fixedShareRect.width;
  }

  fixedShare.style.top = `${stop + window.innerHeight - fixedShareRect.width*2}px`;
  fixedShare.style.bottom = `${articleBottom}px`;
  floatWidget && (floatWidget.style.bottom = `${articleBottom}px`);
  !fixedShare.classList.contains('fixed-share--show') && fixedShare.classList.add('fixed-share--show');

  updateActiveSectionParams();
  checkForFixedSharePosition();
}

function checkForFixedSharePosition() {
  requestAnimationFrame(() => {
    const { activeSection, topOffset} = activeSectionParams;
    let progress = (window.pageYOffset - topOffset) / (activeSection.clientHeight - window.innerHeight) * 100;

    progress = progress > 100 ? 100 : progress;
    progress = progress < 0 ? 0 : progress;

    fixedShare.classList.toggle('fixed-share--fixed', window.scrollY > stop);
    fixedShare.classList.toggle('fixed-share--fixed-bottom', progress == 100);
    floatWidget && floatWidget.classList.toggle('float-widget--fixed-bottom', progress == 100)

    progressEye.textContent = `${Math.floor(progress)}%`;
  });
}

function updateActiveSectionParams() {
  const activeNewsSection = document.querySelector('.article--news.active');

  if(articleNewsWrap && activeNewsSection) {
    const activeSection = activeNewsSection;
    const topOffset = activeSection.getBoundingClientRect().top + window.scrollY;

    activeSectionParams = {
      activeSection: activeSection,
      topOffset: topOffset
    }
  } else {
    activeSectionParams = {
      activeSection: articleSection,
      topOffset: 0
    }
  }
}

function initShareLinks() {
  const shareLinks = document.querySelectorAll('[data-share]');
  const ogTitle = document.querySelector('meta[property="og:title"]');
  const title = document.querySelector('title');
  const shareTitle = ogTitle && ogTitle.content || title && title.text;

  for (const link of shareLinks) {
    const social = link.getAttribute('data-share');

    let url = null;

    if(social === 'fb') {
      url = `https://www.facebook.com/sharer.php?src=sp&u=${location.href}&title=${shareTitle}`;
    } else if(social === 'vk') {
      url = `https://vk.com/share.php?url=${location.href}&title=${shareTitle}`;
    } else if(social === 'tw') {
      url = `https://twitter.com/intent/tweet?text=${shareTitle}&url=${location.href}`;
    }

    if(url) {
      link.setAttribute('href', encodeURI(url));
      link.setAttribute('target', '_blank');
    }
  }
}


export { updateFixedShareLimits, updateActiveSectionParams };
