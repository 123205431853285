import Swiper from "swiper/swiper-bundle";
import { openModal } from '@script/blocks/modal';

const modalGalleryContainerList = document.querySelectorAll('.modal-gallery__container');
const callGalleryBtns = document.querySelectorAll('[data-gallery-id]');

export default function() {
  for (const modalGalleryContainer of modalGalleryContainerList) {
    new Swiper(modalGalleryContainer, {
      enabled: false,
      spaceBetween: 40,
      slidesPerView: 1,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      mousewheel: {
        forceToAxis: true
      },
      breakpoints: {
        639: {
          spaceBetween: 40,
        },
        1023: {
          spaceBetween: 44,
        }
      }
    });
  }

  for (const callGalleryBtn of callGalleryBtns) {
    callGalleryBtn.addEventListener('click', function(e) {
      const galleryId = callGalleryBtn.getAttribute('data-gallery-id');
      const slide = callGalleryBtn.getAttribute('data-gallery-slide');

      e.preventDefault();

      openModal(galleryId, {slideTo: slide});
    });
  }
}
