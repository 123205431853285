import sup from '@script/blocks/sup';

const textBlocks = document.querySelectorAll('.article__block--text');

export default function() {
  for (const textBlock of textBlocks) {
    const footnotes = textBlock.querySelectorAll('footnote');

    for (let i = 0; i < footnotes.length; i++) {
      const footnote = footnotes[i];
      const text = footnote.textContent;
      const title = `&#${9312 + i};`;

      footnote.setAttribute('data-sup-text', text);
      footnote.setAttribute('data-sup-sym', title);
      footnote.innerHTML = title;
      footnote.classList.add('sup');
    }
  }

  sup();
}
