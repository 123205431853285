import scrollTo from '@script/utils/scroll-to';
import { initForms } from '@script/blocks/form';
import emoji from '@script/blocks/emoji';

export default function(selector){
  const root = document.querySelector(selector);

  if(!root){
    console.log('no root');
    return;
  }
  const baseUrl = root.dataset.url;

  if(!baseUrl){
    console.eror('No pagination url is set for', root);
    return;
  }

  const container = root.querySelector(`${selector}__container`);
  const nav       = root.querySelector(`${selector}__nav`);

  function initNav(){
    const moreButton = root.querySelector(`${selector}__nav-more`);
    const nextButton = root.querySelector(`${selector}__nav-next`);
    const prevButton = root.querySelector(`${selector}__nav-prev`);
    const pageButtons = root.querySelectorAll(`${selector}__nav-page[data-page]`);

    if(moreButton){
      moreButton.addEventListener('click', (e) => {
        navBtnCheck(e) && loadNext();
      });
    }

    if(nextButton){
      nextButton.addEventListener('click', (e) => {
        navBtnCheck(e) && loadNext();
      });
    }

    if(prevButton){
      prevButton.addEventListener('click', (e) => {
        navBtnCheck(e) && loadPrev();
      });
    }

    console.log('buttons', pageButtons);

    pageButtons.forEach((b) => {
      b.addEventListener('click', (e) => {
        navBtnCheck(e) && loadPage(parseInt(b.dataset.page));
      });
    });
  }


  let page = 1;
  let loadedPages = [1];

  initNav();

  function navBtnCheck(e) {
    e.preventDefault();

    const href = e.target.getAttribute('href');
    return href !== '#';
  }

  function setPage(p){
    page = p;
    loadedPages.push(p);
    setTimeout(() =>{ scrollTo(`#page-${page}`); }, 0);
  };

  function loadPage(newPage){
    if(loadedPages.indexOf(newPage) > -1){
      setPage(newPage);
      return Promise.resolve();
    }

    toggleNav(false);
    return request(newPage).then((data) => {
      setPage(newPage);
      loadedPages = [];

      container.innerHTML = data.posts;
      nav.innerHTML = data.nav;
      initNav();
      initForms();
      emoji(container);

    }).finally(() => {
      toggleNav(true);
    });
  }

  function loadNext(){
    const newPage = page + 1;

    loadPage(newPage);
  }

  function loadPrev(){
    const newPage = Math.max(1, page - 1);
    loadPage(newPage);
  }

  function toggleNav(toggle){
    root.classList.toggle('posts-loading', toggle);
  }

  function request(page){
    const params = new URLSearchParams({page});
    const url = baseUrl + '?' + params;
    const historyUrl = window.location.pathname+'?' + params;

    const headers = {
      'Accept': 'application/json'
    };

    return fetch(url, {headers}).then((response) => {
      history.pushState({}, null, historyUrl);
      return response.json();
    }).catch((e) => {
      console.log(e);
    });
  }
}
