const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
const article = document.querySelector('.article');

export default function() {
  isSafari && article && window.addEventListener('load', () => {
    setTimeout(() => {
      article.classList.add('no-user-select');
      checkChildNodes(article);
    }, 500);
  });
}

function checkChildNodes(element) {
  const nodes = element.childNodes;

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];

    if (node.childNodes.length) {
      checkChildNodes(node);
    } else {
      if(String(node.textContent).trim() && node.nodeName == '#text') {
        node.parentElement.classList.add('user-select-element');
      }
    }
  }
}
