import { subscribe } from '../utils/api';

export default function() {
  initForms();
}

function initForms() {
  const formWrapList = document.querySelectorAll('.form');

  for (const formWrap of formWrapList) {
    initForm(formWrap);
  }
}

function initForm(formWrap) {
  if (formWrap.classList.contains('initted')) {
    return;
  }

  const input = formWrap.querySelector('.form__input');
  const form = formWrap.querySelector('form');
  const message = formWrap.querySelector('.form__message');
  const submitBtn = formWrap.querySelector('.form__btn');

  let processing = false;

  updateFilledState();

  input.addEventListener('change', updateFilledState);
  form.addEventListener('submit', submitHanlder);

  formWrap.classList.add('initted');

  function submitHanlder(e) {
    e.preventDefault();

    if(!processing && form.checkValidity()) {
      processing = true;

      subscribe(form.email.value)
        .then(() => {
          toggleMessage(true, 'Спасибо!');
          form.reset();
          updateFilledState();
        }).catch(e => toggleMessage(true, e));
    }
  }

  function toggleMessage(show, text) {
    if (show) {
      submitBtn.addEventListener('click', (e) => {
        e.preventDefault();

        toggleMessage(false, '');
      }, { once: true });
    } else {
      processing = false;
    }

    message.innerHTML = text;
    message.title = text;
    form.classList.toggle('form--message', show);
  }

  function updateFilledState() {
    const isFilled = input && input.value && input.value.length ? true : false;
    input.classList.toggle('form__input--filled', isFilled);
  }
}

export { initForms };
