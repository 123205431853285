import scrollTo from '@script/utils/scroll-to';

const header = document.querySelector('.header');
const body = document.querySelector('body');
const searchToggleBtnList = document.querySelectorAll('.header__btn--search-toggle');
const menuToggleBtn = document.querySelectorAll('.header__btn--menu,.mobile-menu__close,.mobile-menu__overlay');
const toTopBtn = document.querySelector('.fixed-share__btn--top');
const fixedShare = document.querySelector('.fixed-share');
const fixedShareMenu = document.querySelector('.fixed-share__menu');
const searchInput = document.querySelector('.search-results__input');
const banner = document.querySelector('.banner__wrapper');

let lastScrollPosition = 0;
let isDesktop;
let isTablet;
let isMobile;

export default function() {
  updateHeaderPosition();
  resizeHandler()

  header && window.addEventListener('resize', resizeHandler);
  header && window.addEventListener('scroll', updateHeaderPosition);
  header && window.addEventListener('load', updateHeaderPosition);

  for (const searchToggleBtn of searchToggleBtnList) {
    searchToggleBtn.addEventListener('click', toggleSearch);
  }

  menuToggleBtn.forEach((e) => e.addEventListener('click', toggleMenu));
  toTopBtn && toTopBtn.addEventListener('click', () => scrollTo('body'));
}

function resizeHandler() {
  isDesktop = window.innerWidth >= 1024;
  isTablet = !isDesktop && window.innerWidth >= 768;
  isMobile = window.innerWidth < 768;
  updateHeaderPosition();
}

function updateHeaderPosition() {
  isMobile && requestAnimationFrame(() => {
    const newScrollPosition = window.scrollY;
    const isDirectionDown = lastScrollPosition < newScrollPosition;
    const sleepzone = 50;

    if(isDirectionDown && newScrollPosition > sleepzone) {
      header.classList.add('header--up');
      header.classList.remove('header--down');
      fixedShare.classList.remove('fixed-share--down');
      fixedShareMenu.classList.remove('fixed-share__menu--open');
    } else {
      header.classList.add('header--down');
      fixedShare.classList.add('fixed-share--down');
      header.classList.remove('header--up');
    }

    if(banner) {
      const bannerHeight = banner.clientHeight;
      header.classList.toggle('header--mobile-banner', newScrollPosition < bannerHeight*2);
    }

    lastScrollPosition = newScrollPosition;
  });
}

function toggleSearch() {
  const isSearchActive = body.classList.contains('body--search');
  const showMenu = body.classList.contains('mobile-menu-shown');
  body.classList.toggle('body--search', !isSearchActive);

  if(!isSearchActive) {
    searchInput.focus();
  }

  if(showMenu) {
    hideMenu();
    body.classList.contains('body--search');
  }

}

function toggleMenu() {
  const isActive = body.classList.contains('mobile-menu-shown');
  const isSearchActive = body.classList.contains('body--search');

  body.classList.toggle('mobile-menu-shown', !isActive);

  if(isSearchActive) {
    hideSearch();
  }
}

function hideMenu() {
  console.log('hideMenu');
  body.classList.toggle('mobile-menu-shown', false);
}

function hideSearch() {
  body.classList.toggle('body--search', false);
}
