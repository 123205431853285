import http from 'superagent';
import { Promise } from 'es6-promise';

const csrf = document.querySelector('[name=csrf-token]');

function getCSRFToken() {
  return csrf && csrf.getAttribute('content');
}

export function fetchSubjects() {
  return new Promise((resolve, reject) => {
    http
      .get('/subjects/published')
      .then((resp) => {
        const { subjects } = JSON.parse(resp.text);

        resolve(subjects);
      }, (err) => {
        reject(err);
      })
    })
}

export function fetchPosts(subject, page = 1) {
  return new Promise((resolve, reject) => {
    http
      .get(`${subject}/posts`)
      .query({
        page: page
      }).then((resp) => {
        const posts = JSON.parse(resp.text);

        setTimeout(() => {
          resolve(posts);
        }, 500) // let the loader spin
      }, (err) => {
        reject(err);
      })
    })
}

export function fetchSearchResults(query = '', page = 1) {
  return new Promise((resolve, reject) => {
    http
      .get('/search/posts')
      .query({
        page: page,
        q: query
      }).then((resp) => {
        const posts = JSON.parse(resp.text);

        resolve(posts);
      }, (err) => {
        reject(err);
      })
    })
}

export function fetchPostsByTag(name = '', page = 1) {
  return new Promise((resolve, reject) => {
    http
      .get(`/tags/${name}/posts`)
      .query({
        page: page
      }).then((resp) => {
        const posts = JSON.parse(resp.text);

        resolve(posts);
      }, (err) => {
        reject(err);
      })
    })
}

export function subscribe(email) {
  return new Promise((resolve, reject) => {
    http
      .post('/subscriptions/add')
      .send({
        subscription: { email }
      })
      .set('X-CSRF-Token', getCSRFToken())
      .then(resolve, err => {
        const { text } = err.response;

        reject(JSON.parse(text).error)
      })
  })
}
