import debounce from '../helpers/debounce';
import { fetchSearchResults } from '../utils/api';

const searchResultsForm = document.querySelector('.search-results form');
const searchInput = document.querySelector('.search-results__input');
const searchResultsList = document.querySelector('.search-results__list');
const searchResultsContainer = document.querySelector('.search-results');
const searchResultsWrapper = document.querySelector('.search-results__wrapper');
const searchLoaderAnimation = document.querySelector('.search-results__loader');

let query = '';
let queryPage = 1;
let queryTotalPages = 1;
const queryMinLength = 2;

const nextPageZone = 200;

export default function() {
  if(searchInput && searchResultsContainer) {
    searchInput.addEventListener('keyup', debounce(updateSearchQuery, 300));
  }

  if(searchResultsForm) {
    searchResultsForm.addEventListener('submit', (e) => e.preventDefault());
  }
}

function updateSearchQuery(event) {
  query = event.target.value;
  queryPage = 1;

  if(query && query.length >= queryMinLength) {
    getResults(true);
  }
}

function searchResultsScrollHandler() {
  if(queryPage < queryTotalPages) {
    const bottomPosition = searchResultsWrapper.getBoundingClientRect().bottom - window.innerHeight;

    if(bottomPosition < nextPageZone) {
      searchResultsContainer.removeEventListener('scroll', searchResultsScrollHandler);
      queryPage++
      getResults(false);
    }
  }
}

function getResults(clean) {
  loaderAnimationToggle(true);

  fetchSearchResults(query, queryPage).then(response => {
    loaderAnimationToggle(false);

    const { meta: { page, total_pages }, posts } = response;
    let postsItems = '';

    query = query;
    queryPage = page;
    queryTotalPages = total_pages;

    posts.forEach(post => {
      postsItems += searchResultTemplate(post, query);
    });

    if(clean) {
      searchResultsList.innerHTML = '';
    }

    searchResultsList.innerHTML += postsItems;

    if(queryPage < queryTotalPages) {
      searchResultsContainer.addEventListener('scroll', searchResultsScrollHandler, {passive: true});
    }
  }).catch(() => {
    loaderAnimationToggle(false);
    searchResultsList.innerHTML = `<li class="search-results__list-item search-results__list-item--error">Ошибка</li>`;
  });
}

function searchResultTemplate(post, query) {
  const { image, slug, title, intro, specials_link } = post;

  const postUrl = specials_link ? specials_link : `/posts/${slug}`;
  const postTitle = title ? `<h4 class="search-results__info-title">${hightlight(title, query)}</h4>` : '';
  const postText = intro ? `<p class="search-results__info-text">${hightlight(intro, query)}</p>` : '';
  const postPreview = image && image.template ? `
    <div class="search-results__preview">
      <a class="search-results__preiview-link" href="${postUrl}" target="_blank">
        <picture>
          <source srcset="${image.template.replace('%{version}', 'search')}.webp" />
          <source srcset="${image.template.replace('%{version}', 'search')}" />
          <img src="${image.template.replace('%{version}', 'search')}" class="search-results__preview-img" alt="" />
        </picture>
      </a>
    </div>
  ` : '';

  return `
    <li class="search-results__list-item">
      ${postPreview}
      <div class="search-results__info">
        <a class="search-results__info-link" href="${postUrl}" target="_blank">
          ${postTitle}
          ${postText}
        </a>
      </div>
    </li>
  `;
}

function hightlight(text, query) {
  const textregexp = new RegExp(query, 'gi');
  return text.replace(textregexp, '<b>$&</b>');
}

function loaderAnimationToggle(state) {
  searchLoaderAnimation.classList.toggle('active', state);
}
