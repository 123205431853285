import Swiper from "swiper/swiper-bundle";

const showMoreBtn = document.querySelector('.subjects-tags__grid-more-btn');
const tagsContainers = document.querySelectorAll('.subjects-tags__tags-container');

export default function() {
  showMoreBtn && showMoreBtn.addEventListener('click', function() {
    const gridWrap = document.querySelector('.subjects-tags__grid-wrap');

    gridWrap && gridWrap.classList.add('subjects-tags__grid-wrap--show-all');
  });

  for (const tagsContainer of tagsContainers) {
    const slides = createTagsDuplicates(tagsContainer);
    const speedFor100px = 2500;
    const speedBase = 500;
    const spaceBetween = 8;
    const tagsSwiper = new Swiper(tagsContainer, {
      spaceBetween: spaceBetween,
      slidesPerView: 'auto',
      loop: true,
      loopedSlides: slides.length,
      speed: speedFor100px,
      freemode: true,
      autoplay: {
        delay: 0,
        pauseOnMouseEnter: true
      },
      mousewheel: {
        forceToAxis: true
      },
      on: {
        init: setSpeedBySlideWidth,
      },
    });

    tagsSwiper.on('activeIndexChange', setSpeedBySlideWidth);
    tagsSwiper.on('sliderFirstMove', function(swiper) {
      tagsSwiper.off('activeIndexChange', setSpeedBySlideWidth);

      swiper.wrapperEl.classList.add('dirty');
      swiper.params.speed = speedBase;
    });

    function setSpeedBySlideWidth(swiper) {
      const nextWidth = swiper.slides[swiper.activeIndex].clientWidth + spaceBetween;
      const speed = speedFor100px/(100/nextWidth);

      swiper.params.speed = speed;
    }
  }
}

function createTagsDuplicates(tagsContainer) {
  const slides = tagsContainer.querySelectorAll('.swiper-slide');
  const tagsWrapper = tagsContainer.querySelector('.subjects-tags__tags-wrapper');

  if(tagsWrapper.clientWidth < window.innerWidth) {
    let repeat = Math.ceil(window.innerWidth/tagsWrapper.clientWidth);

    while (repeat > 0) {
      for (let i = 0; i < slides.length; i++) {
        const element = slides[i].cloneNode(true);
        tagsWrapper.appendChild(element);
      }

      repeat--;
    }
  }

  return tagsContainer.querySelectorAll('.swiper-slide');
}
