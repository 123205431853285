import Swiper from "swiper";

const productSwiperContainerList = document.querySelectorAll('.product-swiper__container');

export default function() {
  for (const productSwiperContainer of productSwiperContainerList) {
    new Swiper(productSwiperContainer, {
      spaceBetween: 40,
      slidesPerView: 'auto',
      // slidesOffsetBefore: 0,
      mousewheel: {
        forceToAxis: true
      },
      breakpoints: {
        639: {
          spaceBetween: 49,
        },
        1023: {
          spaceBetween: 24,
        }
      }
    });
  }
}
