import docReady from "../utils/doc-ready";

export default function() {
  docReady(() => {
    const loadImageList = document.querySelectorAll('[data-load-image-class]');

    for (const loadImage of loadImageList) {
      if(isImageLoaded(loadImage)){
        imageLoaded();
      } else {
        loadImage.addEventListener('load', imageLoaded);
      }

      function imageLoaded() {
        let loadedClass = loadImage.getAttribute('data-load-image-class');

        loadedClass = loadedClass.length ? loadedClass : 'loaded';

        loadImage.classList.add(loadedClass);
      }
    }
  });
}

function isImageLoaded(loadImage) {
  if (typeof loadImage.naturalWidth == 'number' && typeof loadImage.naturalHeight == 'number') {
    return !(loadImage.naturalWidth == 0 && loadImage.naturalHeight == 0);
  } else if (typeof loadImage.complete == 'boolean') {
    return loadImage.complete;
  } else {
    return true;
  }
}
