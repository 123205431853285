// data-sup-sym

const modalClass = 'sup-modal';
const overlayClass = 'sup-modal__overlay';
const closeBtnClass = 'sup-modal__close-btn';
const titleClass = 'sup-modal__title';
const textClass = 'sup-modal__text';
const contentClass = 'sup-modal__content';

export default function() {
  const supElements = document.querySelectorAll('[data-sup-text]');

  if(supElements && supElements.length) {
    buildSupModalElements();
    addSupModalEvents();
  }

  for (const supElement of supElements) {
    supElement.addEventListener('click', function() {
      const title = supElement.getAttribute('data-sup-sym');
      const text = supElement.getAttribute('data-sup-text');

      const {top} = supElement.getBoundingClientRect();

      let content = '';

      content += title ? `<div class="${titleClass}">${title}</div>` : '';
      content += text ? `<div class="${textClass}">${text}</div>` : '';

      if(content.length) {
        const contentElement = document.querySelector(`.${contentClass}`);
        contentElement.innerHTML = content;

        document.querySelector(`.${modalClass}`).style.top = `${top + window.pageYOffset}px`;

        toggleModalVisibility(true);
      }
    });
  }
}

function buildSupModalElements() {
  const tempalte = `
    <div class="${overlayClass}"></div>

    <div class="${modalClass}">
      <button class="${closeBtnClass}"></button>
      <div class="${contentClass}"></div>
    </div>`;

  const holder = document.createElement('div');

  holder.innerHTML = tempalte;

  document.body.appendChild(holder);
}

function addSupModalEvents() {
  document.querySelector(`.${overlayClass}`).addEventListener('click', () => toggleModalVisibility(false));
  document.querySelector(`.${closeBtnClass}`).addEventListener('click', () => toggleModalVisibility(false));
}

function toggleModalVisibility(visible) {
  document.querySelector(`.${overlayClass}`).classList.toggle(`${overlayClass}--visible`, visible);
  document.querySelector(`.${modalClass}`).classList.toggle(`${modalClass}--visible`, visible);
}
