import scrollTo from '@script/utils/scroll-to';
import { updateFixedShareLimits, updateActiveSectionParams } from '@script/blocks/fixed-share';
import emoji from '@script/blocks/emoji';
import { throttle } from "lodash";

export default function(){
  const moreButton = document.querySelector('.article-more__btn');
  const container = document.querySelector('.article__news-wrap');
  const itemClass = 'article--news';

  let intervalList = [];

  if(!container){
    return;
  }

  window.addEventListener('load', function() {
    scrollTo(0);
    if(moreButton){
      moreButton.addEventListener('click', (e) => {
        e.preventDefault();
        if(!moreButton.classList.contains('disabled')){
          loadNext(true);
        }
      })
    }

    updateUrlIntervalList();

    setTimeout(() => scrollTo(0), 100);

    window.addEventListener('scroll', throttle(checkScrollPosition, 500));
    window.addEventListener('resize', updateUrlIntervalList);
  });

  function loadNext(move) {
    console.log('loadNext', move);

    const lastArticle = getLastArticle();
    const nextUrl = lastArticle && lastArticle.dataset.next;

    intervalList[intervalList.length-1].positionLoadNext = -1;

    if(lastArticle && nextUrl) {
      console.log(lastArticle);

      loadArticle(nextUrl, move);
    } else {
      toggleNav(false);
    }
  }

  function toggleNav(toggle){
    const moreButton = document.querySelector('.article-more__btn');
    moreButton && moreButton.classList.toggle('disabled', !toggle);
  }

  function loadArticle(url, move){
    const promise = fetch(url, {
      headers: {"Accept": "application/json"},
    });

    promise.then((response) => {
      return response.json();
    }).then((data) => {
      const html = data.article;
      const holder = document.createElement('DIV');

      holder.innerHTML = html;

      const next = holder.querySelector('*');

      const last = getLastArticle();

      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

      last.parentNode.insertBefore(next, last.nextSibling);

      updateUrlIntervalList();
      updateFixedShareLimits();

      if(move) {
        // Prevent browsers from modifying current scroll position
        scrollTo(scrollTop, 'auto');
        // Scroll top of article into view
        setTimeout(()=>{scrollTo(next)}, 0);
      }

      if(next.dataset.next){
        toggleNav(true);
      } else {
        toggleNav(false);
      }

      emoji(next);
    }).catch((e)=>{
      console.log(e);
      toggleNav(false);
    });
  }

  function getLastArticle(){
    const articles = document.querySelectorAll(`.${itemClass}`);

    return articles[articles.length-1];
  }

  function checkScrollPosition() {
    let url = '';
    const { positionLoadNext } = intervalList[intervalList.length-1];

    if(positionLoadNext >= 0) {
      (positionLoadNext < window.pageYOffset) && loadNext();
    }

    for (const interval of intervalList) {
      url = interval.path;

      if(interval.positionUrl > window.pageYOffset) {
        break;
      }
    }

    updateUrl(url);
  }

  function updateUrl(url) {
    if(location.pathname != url) {
      history.replaceState({}, null, url);

      setActiveSection(url);
      updateActiveSectionParams();
    }
  }

  function setActiveSection(url) {
    const active = document.querySelector(`.${itemClass}[data-self="${url}"]`);

    for (const oldActive of document.querySelectorAll(`.${itemClass}.active`)) {
      oldActive.classList.remove('active');
    }

    active.classList.add('active');
  }

  function updateUrlIntervalList() {
    const articles = document.querySelectorAll(`.${itemClass}`);

    intervalList = [];

    for (const article of articles) {
      const path = article.getAttribute('data-self');
      const { top, bottom } = article.getBoundingClientRect();

      let positionUrl = bottom + window.pageYOffset;
      let positionLoadNext = positionUrl - window.innerHeight*2;

      if(positionLoadNext < 0) {
        positionLoadNext = 0;
      }

      if(articles.length%3 === 0) {
        positionLoadNext = -1;
      }

      if(intervalList.length) {
        intervalList[intervalList.length-1].positionUrl = top + window.pageYOffset;
      }

      intervalList.push({
        path,
        positionUrl: positionUrl,
        positionLoadNext: positionLoadNext
      });
    }
  }
}
