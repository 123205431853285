import docReady from "../utils/doc-ready";

let sentPercents = [];

export default function() {
  docReady(() => {
    if(getDocumentLength() == 0){
      console.log('document length is zero, not sending scroll events');
    }

    window.addEventListener("resize", sendEvent, false);
    window.addEventListener('scroll', sendEvent, false);

    window.addEventListener('scrollPercent', function(e){
      var formatedPercent =  e.detail.percent+'%';

      try{
        fbq('trackCustom', 'ScrollDepth', {
          'Scroll Depth': formatedPercent,
        });
      }catch(e){
        console.error('Scroll tracking error', e);
      }

      try{
        _gaq.push(['_trackEvent', 'Interaction' , 'Scroll', formatedPercent])
      }catch(e){
        console.error('Scroll tracking error', e);
      }
    });
  });
}

function sendEvent() {
  const scrolledPercent = getPercentScrolled();
  const roundedPercent = Math.floor(scrolledPercent / 10) * 10;
  const scrollPercentEvent = new CustomEvent('scrollPercent', {detail: {percent: [roundedPercent]} });

  if(roundedPercent == 0){
    return;
  }

  if(sentPercents.indexOf(roundedPercent) == -1){

    sentPercents.push(roundedPercent);
    window.dispatchEvent(scrollPercentEvent);
  }
}

function getPercentScrolled() {
  const scrollableLength = getScrollableLength();

  if (scrollableLength == 0) {
    return 100;
  } else {
    return getCurrentScrolledLengthPosition() / scrollableLength * 100;
  }
}

function getDocumentLength() {
  const D = document;
  return Math.max(
      D.body.scrollHeight, D.documentElement.scrollHeight,
      D.body.offsetHeight, D.documentElement.offsetHeight,
      D.body.clientHeight, D.documentElement.clientHeight
  )
}

function getWindowLength() {
  return window.innerHeight ||
    (document.documentElement || document.body).clientHeight;
}

function getScrollableLength() {
  const doc = getDocumentLength();
  const win = getWindowLength();
  if (doc > win) {
    return doc - win;
  } else {
    return 0;
  }
}

function getCurrentScrolledLengthPosition() {
 return window.pageYOffset ||
   (document.documentElement || document.body.parentNode || document.body).scrollTop;
}
