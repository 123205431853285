const startScale = 0.844444;

class JumperScroll {
  constructor(jumper, jumperPic, jumperOverlay) {
    this.jumper = jumper;
    this.jumperPic = jumperPic;
    this.jumperOverlay = jumperOverlay;
    this.transitionStarted = false;
    this.handleScroll = this.handleScroll.bind(this);
    this.transition = this.transition.bind(this);

    window.addEventListener('scroll', this.handleScroll, false);

    this.handleScroll();
  }

  handleScroll() {
    const windowHeight = window.innerHeight;
    const jumperRect = this.jumper.getBoundingClientRect();
    const progressContainer = 1 - jumperRect.top/windowHeight*0.5;

    !this.transitionStarted && requestAnimationFrame(() => {
      if(jumperRect.top - windowHeight < 0) {
        let opacity = (progressContainer).toFixed(2) - 0.5;
        let scale = progressContainer;
        let topOffset = 0;

        if(progressContainer > 1) {
          scale = 1;
        } else if(progressContainer < startScale) {
          scale = startScale;
        }

        topOffset = -windowHeight * 0.3 * (1 - (scale - startScale) / (1 - startScale));

        if(opacity > 1) {
          opacity = 1;
        }

        this.jumperOverlay.style.opacity = opacity;

        this.jumperPic.setAttribute('style', `
          transform: scale(${scale}) translate3d(0px, ${topOffset}px, 0px);
        `);

        if(jumperRect.bottom - windowHeight < 5) {
          !this.transitionStarted && this.transition();
        }
      } else {
        this.jumperOverlay.style.opacity = 0;
      }
    });
  }

  transition() {
    const jumperUrl = this.jumper.getAttribute('data-jumper');

    try{
      const event = new CustomEvent('externalLink', {detail: {url: jumperUrl}});
      document.dispatchEvent(event);
    }catch(e){console.error(e)};

    this.transitionStarted = true;

    document.querySelector('body').classList.add('jumper__body');
    document.querySelector('html').classList.add('jumper__html');

    setTimeout(function() {
      document.querySelector('body').classList.add('transition');
      document.querySelector('html').classList.add('transition');
    }, 30);

    setTimeout(function() {
      history.scrollRestoration = 'manual';
      window.location.href = jumperUrl;
      document.body.style.overflow = "hidden"
    }, 500);
  }
}

JumperScroll.init = () => {
  const jumper = document.getElementById('jumper');
  const jumperPic = document.getElementById('jumper__pic');
  const jumperOverlay = document.getElementById('jumper__overlay');

  if(jumper) {
    return new JumperScroll(jumper, jumperPic, jumperOverlay);
  }
}

export default JumperScroll;
