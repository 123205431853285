const printBtnList = document.querySelectorAll('[data-print-target-id]');

export default function() {
  for (const printBtn of printBtnList) {
    printBtn.addEventListener('click', function() {
      const targetId = printBtn.getAttribute('data-print-target-id');
      const targetBlock = document.getElementById(targetId);

      targetBlock && printPage(targetBlock);
    });
  }
}



function closePrint () {
  document.body.removeChild(this.__container__);
}

function setPrint () {
  this.contentWindow.__container__ = this;
  this.contentWindow.onbeforeunload = closePrint;
  this.contentWindow.onafterprint = closePrint;
  this.contentWindow.focus();
  this.contentWindow.print();
}

function printPage (block) {
  const oHiddFrame = document.createElement("iframe");

  oHiddFrame.onload = setPrint;
  oHiddFrame.style.position = "fixed";
  oHiddFrame.style.right = "0";
  oHiddFrame.style.bottom = "0";
  oHiddFrame.style.width = "0";
  oHiddFrame.style.height = "0";
  oHiddFrame.style.border = "0";
  document.body.appendChild(oHiddFrame);

  addPrintContent(oHiddFrame, block);
}

function addPrintContent(oHiddFrame, block) {
  const doc = oHiddFrame.contentWindow.document;
  doc.open();
  doc.write(block.outerHTML);
  doc.close();
}
