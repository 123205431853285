import twemoji from "twemoji";

export default function(container = document.body) {
  if (navigator.appVersion.indexOf('Win') != -1) {
    twemoji.parse(container, {
      attributes() {
        return { loading: 'lazy' }
      }
    });
  }
}
